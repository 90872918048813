import _ from 'lodash';
import moment from 'moment';

const PATHOLOGIES_MAP = {
    "baisse-de-libido": 'libido',
    "ejaculation-precoce": 'premature_ejaculation',
    "andropause": 'andropause',
    "problemes-erection": 'erectile_dysfunction',
    "impuissance": 'erectile_dysfunction',
    "micropenis": 'penile_curvature'
}

export function getPathologySpecialty(categorySlug, pathologySlug = '') {
    let specialty
    switch (categorySlug) {
        case 'sommeil':
            specialty = 'sleep'
            break;
        case 'cheveux':
            specialty = 'hair'
            break;
        case 'digestion':
            specialty = 'digestion'
            break;
        case 'fertilite':
            specialty = 'fertility'
            break;
        case 'sante-sexuelle':
           specialty = _.get(PATHOLOGIES_MAP, pathologySlug, 'other')
           break;
        default:
            specialty = 'other'
            break;
    }
    return specialty
}

export function hasTheShowStarted() {
    // Read the environment variable
  const startShowTime = process.env.GATSBY_START_SHOW_TIME;

  if (!startShowTime) {
    return false
  }

  // Parse the ISO 8601 datetime
  const startDateTime = new Date(startShowTime);

  if (isNaN(startDateTime)) {
    console.error("Invalid datetime format in START_SHOW_TIME.");
    return false
  }

  // Get the current datetime
  const now = new Date();

  // Compare the two datetimes
  if (now < startDateTime) {
    return false
  } else {
    return true
  }
}

export function formatNextMeetDate(date) {
    if (_.isNil(date)) return

    let localDate = moment(date)
    if (!localDate.isValid()) return

    // Change date to user time zone date
    const timeZone = new Date().getTimezoneOffset() * -1
    localDate = localDate.utcOffset(timeZone)

    if (localDate.isSame(moment().utcOffset(timeZone), "day")) {
        return `Dès aujourd'hui ${localDate.format('HH:mm')}`
    }

    if (localDate.isSame(moment().add(1, 'day').utcOffset(timeZone), "day")) {
        return `Dès demain ${localDate.format('HH:mm')}`
    }
}